export interface IAccessInfo {
  functionId: number;
  functionName: string;
  subFunctions: ISubFunction[];
}

export interface ISubFunction {
  subFunctionId: string;
  subFunctionName: string;
  operations: IOperation[];
}

export interface IOperation {
  id: string;
  name: OPERATION;
  hasAccess: boolean;
}

export enum OPERATION {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum SUB_FUNCTION {
  ATTRIBUTES = 'Attributes',
  AUDIENCES = 'Audiences',
  CUSTOMAUDIENCES = 'Custom Audiences',
  STANDARDAUDIENCES = 'Standard Audiences',
  CAMPAIGNS = 'Campaigns',
  USER_MANAGEMENT = 'User Management',
  RATE_CARD_PRICING = 'Ratecard Pricing',
  ORGANISATION_MANAGEMENT = 'Organisation Management',
  AGENCY_ASSOCIATIONS = 'Agency Associations',
  CLIENTS = 'Clients',
  CURRENCY = 'Currency',
  DISCOUNT_RATE = 'Discount Rate',
  FIXED_PRICE_AMOUNT = 'Fixed Price Amount',
  USER_ROLES = 'User Roles',
  GEO_AND_PUBLISHER_DISCOUNT = 'Geography and Publisher Discounts',
  TELCO_AND_PRICEBANDS = 'Telcos and Price Bands',
  ATTRIBUTE_ONBOARDING = 'Attribute Onboarding',
  ATTRIBUTE_MANAGEMENT = 'Attribute Management',
  BESPOKE_ATTRIBUTES = 'Bespoke attributes',
  CRM_FILE_VERSIONS = 'CRM File Versions',
  PRESET_MANAGEMENT = 'Preset Management',
  LOCALISATIONS = 'Localisations',
  BLOCKLISTS = 'Blocklists',
  SUPPORT = 'Support'
}
