import { AUTHORIZATION_API_URL as API_URL, LANGUAGE_CODE } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { GlossaryModel } from '@/_types';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public addGlossary(glossary: GlossaryModel) {
    return this.http.post(`${API_URL.GET_GLOSSARY}/${LANGUAGE_CODE.EN}`, glossary);
  }

  public deleteGlossary(glossary: GlossaryModel) {
    return this.http.delete(`${API_URL.GET_GLOSSARY}/${LANGUAGE_CODE.EN}/${glossary.id}`);
  }

  public getGlossary() {
    return this.http.get(`${API_URL.GET_GLOSSARY}/${LANGUAGE_CODE.EN}`);
  }

  public getManual(iso: string = 'en') {
    const httpOptions = {
      responseType: 'blob'
    };
    return this.http.getFile(API_URL.GET_SUPPORT_MANUAL + (iso ?? 'en'), httpOptions);
  }

  public saveManual(data: any, iso: string = 'en') {
    return this.http.post(API_URL.GET_SUPPORT_MANUAL + (iso ?? 'en'), data);
  }

  public saveGlossary(glossary: GlossaryModel) {
    return this.http.put(`${API_URL.GET_GLOSSARY}/${LANGUAGE_CODE.EN}/${glossary.id}`, glossary);
  }
}
