import { NovatiqAuthorizationClient } from '@/_auth-client';
import { mainMenuItems, navigationMenuItems, ROUTES } from '@/_contants';
import { IAccessInfo } from '@/_types/models/authorization/access-info.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapSideBarData {
  constructor(private readonly authorizationClient: NovatiqAuthorizationClient) {
    this.initLoad();
  }

  /**
   * Clones an object using recursion to handle nested objects.
   * @param obj - The object to be cloned.
   * @returns A deep copy of the object.
   */
  private clone(obj: any): any {
    if (!obj || typeof obj !== 'object') {
      return obj;
    }
    const temp = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach((key) => {
      temp[key] = this.clone(obj[key]);
    });
    return temp;
  }

  /**
   * Initializes the loading of menu items based on user access.
   * @returns An array of sorted menu items that the user has access to.
   */
  public initLoad(): any[] {
    const userAccessInfo: IAccessInfo[] = this.authorizationClient.userAccessInfo || [];

    // Filter pages to find those with at least one operation that has access.
    const pagesWithAccess: IAccessInfo[] = userAccessInfo.filter(({ subFunctions }) =>
      subFunctions.some(({ operations }) => operations.some(({ hasAccess }) => hasAccess))
    );

    // Get the names of functions and subfunction the user has access to.
    // Step 1: Initialize arrays to hold function names and subfunction names
    const functionNames = [];
    const subFunctionNames = [];

    // Step 2: Iterate through the main array to extract functionNames
    pagesWithAccess.forEach((item) => {
      // Push the functionName to the array
      functionNames.push(item.functionName);

      // Step 3: Check and iterate through the subFunctions array
      if (Array.isArray(item.subFunctions)) {
        item.subFunctions.forEach((subFunction) => {
          // check if the subfunction has atleastone access before pushing to menu
          if (subFunction.operations.some(({ hasAccess }) => hasAccess)) {
            // Push the subFunctionName to the array
            subFunctionNames.push(subFunction.subFunctionName);
          }
        });
      }
    });
    // Build allocated menu based on accessible page names.
    let allocatedMenu = navigationMenuItems.filter(
      (menu) => functionNames.includes(menu.name) || subFunctionNames.includes(menu.name)
    );

    const zenithStatus = this.authorizationClient.loggedInUser?.organisation?.zenithStatus;
    if (zenithStatus !== 'Active') {
      allocatedMenu = allocatedMenu.filter((item) => {
        if (item.name === ROUTES.ZENITH_BILLING.name) {
          return false;
        }
        return true;
      });
    }

    // Construct final menu items.
    const menuItems = this.menuBuilder(allocatedMenu);

    // Sort menu items by ID.
    return menuItems.sort((a, b) => a.id - b.id);
  }

  /**
   * Builds the sidebar menu based on the allocated functions.
   * @param allowedFunctions - Array of allowed menu functions.
   * @returns Sidebar menu structured with main and sub-items.
   */
  private menuBuilder(allowedFunctions: any[]): any[] {
    // Initialize an empty sidebar menu array
    const sideBarMenu: any[] = [];

    // Iterate over the allowed functions to build the sidebar menu
    allowedFunctions.forEach((menu) => {
      // Find the navigation menu item that matches the current menu's label
      const subMenuItem = navigationMenuItems.find((navigationMenu) => navigationMenu.label === menu.label);

      if (subMenuItem) {
        // Clone the main menu item based on the parentId of the found submenu item
        const mainMenu = this.clone(mainMenuItems.find((mainMenu) => mainMenu.id === subMenuItem.parentId));

        // Check if the main menu item is already included in the sideBarMenu
        if (mainMenu && !sideBarMenu.some((existingMenu) => existingMenu.id === mainMenu.id)) {
          // If not present, push the cloned main menu item into the sidebar menu
          sideBarMenu.push(mainMenu);
        }

        // Find the existing main menu object in the sidebar menu
        const existingMenu = sideBarMenu.find((existingMenu) => existingMenu.id === mainMenu.id);
        if (existingMenu && subMenuItem.isChildMenu) {
          // Ensure the existingMenu has an items array
          existingMenu.items = existingMenu.items || [];

          // Check if the submenu item is already included in the existing menu's items
          if (!existingMenu.items.some((item) => item.id === subMenuItem.id)) {
            // If not, push the submenu item to the existing menu's items array
            existingMenu.items.push(subMenuItem);
          }
        }
      }
    });

    // Return the constructed sidebar menu
    return sideBarMenu;
  }
}
