export const ADMINISTRATION_API_URL = {
  CLIENT: 'client',
  GET_BRAND: 'brands',
  COUNTRIES: 'countries',
  CATEGORIES: 'categories',
  CATEGORIES_ALL: 'categories-all',
  CAMPAIGN_CATEGORIES: 'v1/campaign-category',
  DATA_OWNER_MNO: 'data-owners/mno',
  GET_CLIENTS: 'data-partners/clients',
  BRAND_BLOCKLIST: 'brandBlackLists',
  BRAND_BLOCKLISTS: 'brand-blocklists',
  GET_DATA_PARTNER: 'data-partners/country',
  CATEGORY_BLOCKLIST: 'category-blocklist',
  GET_ATTRIBUTE_PRICING: 'attribute-pricing',
  GET_ATTRIBUTE_DISCOUNT: 'attribute-discounts',
  POST_PRICING: 'pricing',
  SIGNIN: 'sisense/signin',
  FUNCTION_NAME: 'functionName',
  SISENSE_DASHBOARD: 'sisense/dashboard',
  QUICKSIGHT_DASHBOARD: 'v1/quicksight/signin',
  BUY_SIDES: 'buysides',
  CURRENCIES: 'currencies',
  METADATA: 'metadata',
  PUBLISHER: 'publisher',
  ORGANISATION: 'organisation',
  TITLES: 'titles',
  JAVA_V2: 'J/v2/',
  AUDIT_LOG: 'audit-messages'
};
