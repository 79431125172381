import { DISCOUNT_TYPE_CONSTANT } from '@/_contants';
import { ClientManagementService } from '@/_services';
import { ClientManagementModel, DiscountModel, FixedPriceModel } from '@/_types';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AdminClientManagementHelper {
  clientManagementDetails: ClientManagementModel;

  constructor(private readonly clientManagementService: ClientManagementService) {}

  getClientList(buySideId = '', country = '', client = '') {
    return this.clientManagementService.getClientList(buySideId, country, client);
  }

  generateDataSource(client): ClientManagementModel {
    this.clientManagementDetails = client;
    return this.clientManagementDetails;
  }

  getPageDataSource(generatedClientList) {
    generatedClientList = generatedClientList.sort(
      this.sortBy('totalCampaigns', 'campaignClientsResponseList', 'desc', generatedClientList)
    );
    for (let i = 0; i < generatedClientList.length; i++) {
      const dsp = generatedClientList[i];
      dsp.campaignClientsResponseList = _.orderBy(
        dsp.campaignClientsResponseList,
        ['fixedRateNotes', 'discountNotes'],
        ['asc']
      );

      generatedClientList[i] = dsp;
    }
    return generatedClientList.sort(this.sortBy('name', null, 'asc', generatedClientList));
  }

  submitDiscountRate(discountModel: DiscountModel, submitType: any, country: any) {
    switch (submitType) {
      case DISCOUNT_TYPE_CONSTANT.REQUEST_FOR_CLIENT:
        return this.clientManagementService.requestDiscountForClient(discountModel);
        break;
      case DISCOUNT_TYPE_CONSTANT.REQUEST_FOR_DATAPARTNER:
        return this.clientManagementService.requestDiscountForDataPartner(discountModel);
        break;
      case DISCOUNT_TYPE_CONSTANT.ACCEPT_FOR_CLIENT:
        return this.clientManagementService.acceptDiscountForClient(discountModel.organisationId, {
          accepted: true,
          country: _.isEmpty(country) ? null : country
        });
        break;
      case DISCOUNT_TYPE_CONSTANT.ACCEPT_FOR_DATAPARTNER:
        return this.clientManagementService.acceptDiscountForDataPartner(discountModel.organisationId, {
          accepted: true,
          country: _.isEmpty(country) ? null : country
        });
        break;
      case DISCOUNT_TYPE_CONSTANT.APPLY_FOR_CLIENT:
        return this.clientManagementService.applyDiscountForClient(
          discountModel.organisationId,
          discountModel.amount,
          discountModel.country
        );
        break;
      case DISCOUNT_TYPE_CONSTANT.APPLY_FOR_DATAPARTNER:
        return this.clientManagementService.applyDiscountForDataPartner(
          discountModel.organisationId,
          discountModel.amount,
          discountModel.country
        );
        break;
      case DISCOUNT_TYPE_CONSTANT.REJECT_FOR_CLIENT:
        return this.clientManagementService.rejectDiscountForClient(discountModel.organisationId, {
          accepted: false,
          country: _.isEmpty(country) ? null : country
        });
        break;
      case DISCOUNT_TYPE_CONSTANT.REJECT_FOR_DATAPARTNER:
        return this.clientManagementService.rejectDiscountForDataPartner(discountModel.organisationId, {
          accepted: false,
          country: _.isEmpty(country) ? null : country
        });
        break;
    }
  }

  submitFixedprice(fixedPriceModel: FixedPriceModel, submitType: any, country: any) {
    switch (submitType) {
      case DISCOUNT_TYPE_CONSTANT.REQUEST_FOR_CLIENT:
        return this.clientManagementService.requestFixedpriceForClient(fixedPriceModel);
        break;
      case DISCOUNT_TYPE_CONSTANT.ACCEPT_FOR_CLIENT:
        return this.clientManagementService.acceptFixedPriceForClient(fixedPriceModel.clientId, {
          accepted: true,
          country: _.isEmpty(country) ? null : country
        });
        break;

      case DISCOUNT_TYPE_CONSTANT.APPLY_FOR_CLIENT:
        return this.clientManagementService.applyFixedRateForClient(
          fixedPriceModel.clientId,
          fixedPriceModel.fixedRate,
          fixedPriceModel.currency,
          fixedPriceModel.country
        );
        break;
      case DISCOUNT_TYPE_CONSTANT.REJECT_FOR_CLIENT:
        return this.clientManagementService.rejectFixedPriceForClient(fixedPriceModel.clientId, {
          accepted: false,
          country: _.isEmpty(country) ? null : country
        });
        break;
    }
  }

  setCurrency(buySideId: string, currencyCode: string) {
    const body = {
      dataPartnerId: buySideId,
      currency: currencyCode
    };
    return this.clientManagementService.setCurrency(body);
  }

  private sortBy(key, parent, order, clientList) {
    return function innerSort(a, b) {
      let varA = '';
      let varB = '';
      if (parent) {
        varA = typeof a[parent][key] === 'string' ? a[parent][key].toUpperCase() : a[parent][key];
        varB = typeof b[parent][key] === 'string' ? b[parent][key].toUpperCase() : b[parent][key];
      } else {
        varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
      }
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
}
