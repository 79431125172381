import { AUTHORIZATION_API_URL as API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { UserModel } from '@/_types/models/usermanagement/user.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  private readonly UserList: BehaviorSubject<UserModel[]>;

  constructor(private readonly http: NovatiqHttpClient) {}

  public getUserList() {
    return this.UserList.asObservable();
  }

  public getUsers() {
    return this.http.get(API_URL.GET_USERS);
  }

  public getOrganisations(organisationType?: string) {
    const organisationTypeParam = organisationType ? `organisationType=${organisationType}` : '';
    return this.http.get(`${API_URL.GET_ORGANISATIONS}?${organisationTypeParam}`);
  }

  public postUser(data: any) {
    return this.http.post(API_URL.POST_USERS, data);
  }

  public updateUser(userId: any = {}, data: any) {
    return this.http.patch(`${API_URL.GET_USERS}/${userId}`, data);
  }

  public deactivateUser(data: any = {}) {
    return this.http.post(API_URL.POST_USERS_DEACTIVATE, data);
  }

  public reactivateUser(data: any = {}) {
    return this.http.post(API_URL.POST_USERS_REACTIVATE, data);
  }

  public resendActivationMailToUser(data: any = {}) {
    return this.http.post(API_URL.RESEND_ACTIVATION_EMAIL, data);
  }

  public unblockUser(data: any = {}) {
    return this.http.post(API_URL.POST_USERS_UNBLOCK, data);
  }

  public deleteUser(userId: string) {
    return this.http.delete(`${API_URL.USER}/${userId}`);
  }
}
