import { ADMINISTRATION_API_URL as API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrandBlocklistsService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public getBrandBlocklist(organisationName: string) {
    const DATA_OWNER_ID = 'dataOwnerId';
    let params = new HttpParams();
    params = params.append(DATA_OWNER_ID, organisationName);
    return this.http.get(API_URL.BRAND_BLOCKLISTS, params);
  }

  public getBrands() {
    return this.http.get(API_URL.GET_BRAND);
  }

  public saveBrands(data: any) {
    return this.http.post(API_URL.BRAND_BLOCKLISTS, data);
  }
}
