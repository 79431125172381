import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NovatiqMessageService {
  private MessageSubject = new Subject<any>();

  sendMessage(message: string, severity: string) {
    this.MessageSubject.next({ text: message, severity: severity, key: 'tr' });
  }

  clearMessage() {
    this.MessageSubject.next(null);
  }

  getMessage(): Observable<any> {
    return this.MessageSubject.asObservable();
  }

  sendAllErrorMessages(messages: string[]): void {
    messages.forEach((message) => {
      this.sendMessage(message, 'error');
    });
  }
}
