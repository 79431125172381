/**
 * Represents different types of organizations in the system
 */
export enum ORGANISATION_TYPE {
  TELCO = 'Telco',
  BUY_SIDE = 'Buy-side',
  NOVATIQ = 'Novatiq',
  AGENCY = 'Agency',
  BUYSIDE_PUBLISHER = 'Buy-side Publisher',
  DISTRIBUTION_PUBLISHER = 'Distribution Publisher',
  BUYSIDE_SSP = 'Buy-side SSP',
  DISTRIBUTION_SSP = 'Distribution SSP'
}
