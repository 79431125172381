import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
// import ngx-translate and the http loader
import { TranslateHttpLoader } from '@codeandweb/http-loader';
import { TranslateLoader, TranslateModule } from '@codeandweb/ngx-translate';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { NgChartsModule } from 'ng2-charts';
import { defineLocale, hiLocale, trLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-om-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalErrorHandler } from './_handlers';
import {
  NovatiqHttpErrorHandlerInterceptor,
  NovatiqHttpInterceptor,
  NovatiqLoadingSpinnerInterceptor
} from './_interceptors';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthorizationLayoutComponent, ErrorLayoutComponent } from './layouts';
import { AppLayoutModule } from './layouts/app.layout.module';
import { SharedModule } from './shared/shared.module';
// Define the locales you want to support in ngx-bootstrap
defineLocale('tr', trLocale);
defineLocale('hi', hiLocale);

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const APP_CONTAINERS = [AuthorizationLayoutComponent, ErrorLayoutComponent];

const LIVE_HOST = 'www.fusion-platform.com';
const STAGING_HOST = 'd3txz191q445kh.cloudfront.net';

const CAPTCHA_STAGING = '6LfzCCcdAAAAAIDSawFrzYQSIsrMFLtWmBFON5gU';
const CAPTCHA_LIVE = '6Ldp-yYdAAAAAG-esDbYrKOGZRGdArPqQbMDFZxG';
const CAPTCHA_TEST = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
@NgModule({
  declarations: [AppComponent, ...APP_CONTAINERS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    AppLayoutModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    NgChartsModule,
    FormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    RouterModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NovatiqHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NovatiqHttpErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NovatiqLoadingSpinnerInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey:
          window.location.host === LIVE_HOST
            ? CAPTCHA_LIVE
            : window.location.host === STAGING_HOST
              ? CAPTCHA_STAGING
              : CAPTCHA_TEST
      } as RecaptchaSettings
    },
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
