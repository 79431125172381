import { ROUTES } from '@/_contants';
import { AuthorizationHelper, ErrorHandlingHelper, NovatiqPopupHelper } from '@/_helpers';
import { PageSettingService } from '@/_services';
import { LanguageService } from '@/_services/language.service';
import { IUserInfo, LoginModel } from '@/_types';
import { NovatiqMessageService } from '@/layouts/service/message.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@codeandweb/ngx-translate';

@Component({
  selector: 'novatiq-expiry-lock',
  templateUrl: './novatiq-expiry-lock.component.html'
})
export class NovatiqExpiryLockComponent implements OnInit, OnDestroy {
  @Input() currentUser: IUserInfo;

  @Output() resumeSession: EventEmitter<any>;

  apiError: string;
  loginForm: UntypedFormGroup;
  pageSettings: any;

  get formControls() {
    return this.loginForm.controls;
  }

  private get loginModel() {
    return {
      username: this.modelData.username,
      password: this.modelData.password
    } as LoginModel;
  }

  private get modelData() {
    return this.loginForm.value;
  }

  constructor(
    private readonly authorizationHelper: AuthorizationHelper,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly pageSettingService: PageSettingService,
    private readonly popupHelper: NovatiqPopupHelper,
    public translate: TranslateService,
    private messageService: NovatiqMessageService,
    private languageService: LanguageService,
    private errorHandlingHelper: ErrorHandlingHelper
  ) {
    this.resumeSession = new EventEmitter<any>();
  }

  ngOnInit() {
    this.getPageSettings();
    this.initializeProperties();
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.authorizationHelper.logIn(this.loginModel, true).subscribe(
      (authResponse: any) => {
        const userLocale = authResponse?.userInfo?.language;

        const isDefaultLanguageSelected = Boolean(userLocale); // Treats "" as false
        if (isDefaultLanguageSelected) {
          this.languageService.useLanguage(userLocale);
          this.languageService.setSessionLanguage(userLocale);
        }
        this.resumeSession.emit(authResponse);
        this.loginForm.controls?.password?.setValue('');
        this.popupHelper.close();
      },
      (error) => {
        if (error.errors) {
          const errorMessages = error.errors.map((err) => err.error);
          this.messageService.sendAllErrorMessages(errorMessages);
        } else {
          this.messageService.sendAllErrorMessages([error.message]);
        }
        return this.errorHandlingHelper.handleError(error);
      }
    );
  }

  forgotPassword() {
    this.popupHelper.close();
    this.authorizationHelper.removeUserSessionAndRedirectToForgottenPassword();
  }

  private getPageSettings() {
    this.pageSettingService.get(ROUTES.LOGIN.path).subscribe((settings) => {
      this.pageSettings = settings;
    });
  }

  private initializeProperties() {
    this.apiError = '';
    this.loginForm = this.formBuilder.group({
      username: [this.currentUser?.userName],
      password: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.loginForm.reset();
  }
}
