import { NovatiqAuthorizationClient } from '@/_auth-client';
import { ROUTES } from '@/_contants';
import { AuthorizationService } from '@/_services';
import { LoginModel } from '@/_types';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationHelper {
  constructor(
    private readonly authorizationClient: NovatiqAuthorizationClient,
    private readonly authorizationService: AuthorizationService,
    private readonly router: Router
  ) {}

  logIn(data: LoginModel, noRedirect = false): Observable<any> {
    return this.authorizationService.logIn(data).pipe(
      switchMap((authInfo) => {
        // Store the auth token
        this.authorizationClient.addToken(authInfo);

        // Get the user info after logging in
        return this.authorizationService.getUser().pipe(
          switchMap((userInfo) => {
            // Store user info
            this.authorizationClient.addUser(userInfo);

            // Build a response object for our caller
            const authResponse: any = { ...authInfo, userInfo };

            // Check 2FA requirements
            /**
             * If userInfo.require2FA then the user has to go through the 2fa flow
             * else just login the user without 2fa
             * if require2FA is enabled check if the user has setup the auth device.
             * if setup complete then ask him to verify code and redirect to app.
             * else take him to setup the auth code via QR
             */
            if (authInfo.require2FA) {
              if (authInfo.setupComplete2FA) {
                this.router.navigate([ROUTES.VERIFY_2FA.url]);
              } else {
                this.router.navigate([ROUTES.SETUP_2FA.url]);
              }
              // Return the response without calling getUserAccessInfo
              return of(authResponse);
            } else {
              // If no 2FA required
              if (!noRedirect) {
                // If we do want to redirect, then call getUserAccessInfo first
                // before navigating to the landing page
                return this.authorizationService.getUserAccessInfo().pipe(
                  tap((userAccessInfo) => {
                    this.authorizationClient.addAccess(userAccessInfo);
                    // Now that we have user access info, redirect
                    this.router.navigate([this.authorizationClient.landingPage]);
                  }),
                  // Transform the Observable so we still return the final authResponse
                  map(() => authResponse)
                );
              } else {
                // If no redirect, just return the authResponse as is
                return of(authResponse);
              }
            }
          })
        );
      })
    );
  }

  logOut() {
    if (!this.authorizationClient.accessToken || this.authorizationClient.sessionExpired) {
      this.removeUserSessionAndRedirectToLogin();
    }
    return this.authorizationService.logOut().pipe(
      tap((response) => {
        this.removeUserSessionAndRedirectToLogin();
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  refreshToken() {
    this.authorizationService
      .refreshToken()
      .pipe(
        tap((response) => {
          this.authorizationClient.refreshUserSession(response);
        }),
        catchError((error) => {
          throw error;
        })
      )
      .subscribe();
  }

  async verifyToken(type: string, token: string) {
    try {
      return await this.verifyTokenAsync(token);
    } catch (error) {
      if (type === ROUTES.PASSWORD_RESET.type) {
        this.router.navigate([ROUTES.FORGOT_PASSWORD.path]);
      } else if (type === ROUTES.USER_ACTIVATION.type) {
        this.router.navigate([ROUTES.PAGE_400V.path]);
      }
    }
  }

  public removeUserSessionAndRedirectToForgottenPassword() {
    this.removeUserSessionAndRedirectTo(ROUTES.FORGOT_PASSWORD.path);
  }

  public removeUserSessionAndRedirectToLogin() {
    this.removeUserSessionAndRedirectTo(ROUTES.LOGIN.path);
  }

  private verifyTokenAsync(token: string) {
    return this.authorizationService.verifyToken(token).toPromise();
  }

  private removeUserSessionAndRedirectTo(path: string) {
    this.authorizationClient.removeUserSession();
    this.router.navigate([path]);
  }
}
