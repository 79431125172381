import { API_ERROR_CODE, PAGE_SETTING_URL } from '@/_contants';
import { GoAPIError, HTTP_ERROR_CODE, NovatiqError } from '@/_types';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { AuthorizationHelper } from '../authorization';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingHelper {
  constructor(
    private readonly authorizationHelper: AuthorizationHelper,
    private readonly router: Router
  ) {}

  handleApiError(httpErrorResponse: HttpErrorResponse): Observable<never> {
    if (httpErrorResponse.error) {
      const hasGoAPIErrors = !!httpErrorResponse.error.errors;
      const hasJavaAPIErrors = !!httpErrorResponse.error.errorInfo;
      const hasAssetErrors = httpErrorResponse.url.includes(PAGE_SETTING_URL.CONFIGURATION_URL);

      if (hasAssetErrors || (!hasGoAPIErrors && !hasJavaAPIErrors)) {
        return this.handleGenericError(httpErrorResponse);
      } else if (hasGoAPIErrors) {
        return this.handleGoAPIErrors(httpErrorResponse);
      } else if (hasJavaAPIErrors) {
        return this.handleJavaAPIErrors(httpErrorResponse);
      }
    }
    return this.handleGenericError(httpErrorResponse);
  }

  private handleGenericError(httpErrorResponse: HttpErrorResponse): Observable<never> {
    return throwError(() => ({
      code: httpErrorResponse.status,
      message: httpErrorResponse.message,
      class: httpErrorResponse.name
    }));
  }

  private handleGoAPIErrors(httpErrorResponse: HttpErrorResponse): Observable<never> {
    const goAPIErrors: GoAPIError[] = httpErrorResponse.error.errors;
    const errorMessages = goAPIErrors.map((error) => `Field '${error.field}' has error: ${error.error}`).join('\n');
    return throwError(() => ({
      code: httpErrorResponse.status,
      message: errorMessages,
      class: httpErrorResponse.name,
      errors: goAPIErrors // Send the array object back to the calling function
    }));
  }

  private handleJavaAPIErrors(httpErrorResponse: HttpErrorResponse): Observable<never> {
    const apiError = httpErrorResponse.error.errorInfo;
    if (apiError.errorCode === HTTP_ERROR_CODE.AUTHENTICATION_TOKEN_EXPIRED) {
      this.authorizationHelper.removeUserSessionAndRedirectToLogin();
    }
    return throwError(
      () =>
        ({
          code: apiError.errorCode,
          message: API_ERROR_CODE[apiError.errorCode] || apiError.errorMessage,
          class: apiError.errorClass,
          responseData: httpErrorResponse.error?.responseData
        }) as NovatiqError
    );
  }

  handleError(error: any): Observable<never> {
    console.error('Error occurred:', error);
    return throwError(() => error);
  }
}
