import { AUTHORIZATION_API_URL as API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { AdminClientManagementModel, DiscountModel, FixedPriceModel } from '@/_types';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientManagementService {
  private readonly clientList: BehaviorSubject<AdminClientManagementModel[]>;

  constructor(private readonly http: NovatiqHttpClient) {}

  public getClientList(buySideId, country, clientId) {
    return this.http.get(`${API_URL.CLIENT_MANAGEMENT}?buySideId=${buySideId}&country=${country}&clientId=${clientId}`);
  }
  public getClientListForDataPartner(dataPartnerName) {
    return this.http.get(`${API_URL.CLIENTS}?dataPartnerName=${dataPartnerName}`);
  }

  public getFilterList() {
    return this.http.get(API_URL.CLIENTS);
  }

  public requestDiscountForDataPartner(discountModel: DiscountModel) {
    const body = {
      dataPartnerId: discountModel.organisationId,
      country: _.isEmpty(discountModel.country) ? null : discountModel.country,
      discount: discountModel.amount,
      comments: discountModel.supportingNotes
    };
    return this.http.post(API_URL.BUYSIDE_DISCOUNT, body);
  }

  public requestDiscountForClient(discountModel: DiscountModel) {
    const body = {
      clientId: discountModel.organisationId,
      country: _.isEmpty(discountModel.country) ? null : discountModel.country,
      discount: discountModel.amount,
      comments: discountModel.supportingNotes
    };
    return this.http.post(API_URL.CLIENT_DISCOUNT, body);
  }

  public requestFixedpriceForClient(fixedPriceModel: FixedPriceModel) {
    const body = {
      clientId: fixedPriceModel.clientId,
      country: null,
      fixedRate: fixedPriceModel.fixedRate,
      currency: fixedPriceModel.currency,
      comments: fixedPriceModel.comments
    };
    return this.http.post(API_URL.CLIENT_FIXED_RATE, body);
  }

  public applyDiscountForClient(clientId: any, discount: any, country: any = null) {
    const body = {
      clientId: clientId,
      country: _.isEmpty(country) ? null : country,
      discount: discount,
      comments: null
    };
    return this.http.post(API_URL.CLIENT_DISCOUNT, body);
  }

  public applyFixedRateForClient(clientId: any, fixedRate: any, currency: any, country: any = null) {
    const body = {
      clientId: clientId,
      country: null,
      fixedRate: fixedRate,
      currency: currency,
      comments: null
    };
    return this.http.post(API_URL.CLIENT_FIXED_RATE, body);
  }

  public applyDiscountForDataPartner(dataPartnerId: any, discount: any, country: any = null) {
    const body = {
      dataPartnerId: dataPartnerId,
      country: _.isEmpty(country) ? null : country,
      discount: discount,
      comments: null
    };
    return this.http.post(API_URL.BUYSIDE_DISCOUNT, body);
  }

  public acceptDiscountForClient(clientId: any = {}, body: any) {
    return this.http.put(`${API_URL.CLIENT_DISCOUNT}?clientId=${clientId}`, body);
  }

  public acceptFixedPriceForClient(clientId: any = {}, body: any) {
    return this.http.put(`${API_URL.CLIENT_FIXED_RATE}?clientId=${clientId}`, body);
  }

  public rejectFixedPriceForClient(clientId: any = {}, body: any) {
    return this.http.put(`${API_URL.CLIENT_FIXED_RATE}?clientId=${clientId}`, body);
  }

  public rejectDiscountForClient(clientId: any = {}, body: any) {
    return this.http.put(`${API_URL.CLIENT_DISCOUNT}?clientId=${clientId}`, body);
  }

  public acceptDiscountForDataPartner(id: any = {}, body: any) {
    return this.http.put(`${API_URL.BUYSIDE_DISCOUNT}?buySideId=${id}`, body);
  }

  public rejectDiscountForDataPartner(id: any = {}, body: any) {
    return this.http.put(`${API_URL.BUYSIDE_DISCOUNT}?buySideId=${id}`, body);
  }

  public setCurrency(body: IBuySideCurrency) {
    return this.http.post(API_URL.BUYSIDE_CURRENCY, body);
  }

  public setOrganisationClientMapping(body: any) {
    return this.http.post(API_URL.ORGANISATION_CLIENT, body);
  }

  public getClientBuySide() {
    return this.http.get(`${API_URL.CLIENTS_BUYSIDE}`);
  }

  public createClient(body: IClient) {
    return this.http.post(API_URL.CLIENTS, body);
  }

  public deleteClient(clientId: any) {
    return this.http.put(`${API_URL.DELETE_CLIENT}/${clientId}`);
  }

  public setOrganisationBuysideMapping(body: any) {
    return this.http.post(API_URL.ORGANISATION_BUYSIDE, body);
  }

  public removeOrganisationBuySideMapping(keyValues: any) {
    return this.http.delete(API_URL.ORGANISATION_BUYSIDE + '?' + keyValues);
  }

  public saveFixedPriceForOrganisation(body: IBuySideFixedPrice) {
    return this.http.post(API_URL.BUYSIDE_FIXED_RATE, body);
  }
}

export interface IClient {
  clientName: string;
  buySideId: string;
  countries: string[];
}

export interface IBuySideFixedPrice {
  dataPartnerId: number;
  fixedRate: number;
}

export interface IBuySideCurrency {
  dataPartnerId: string;
  currency: string;
}
