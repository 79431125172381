import { AUTHORIZATION_CLIENT, navigationMenuItems, ORGANISATION_TYPE, ROUTES } from '@/_contants';
import { NovatiqGeographyHelper } from '@/_helpers';
import { EncryptionHelper } from '@/_helpers/encryption';
import { LanguageService } from '@/_services/language.service';
import { AuthorizationTokenModel, IUserInfo, TIME_CONVERSION } from '@/_types';
import { IAccessInfo, OPERATION, SUB_FUNCTION } from '@/_types/models/authorization/access-info.model';
import { IComponentPermission } from '@/shared/directive/has-permission.directive';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NovatiqAuthorizationClient {
  private readonly currentUser: BehaviorSubject<IUserInfo>;
  private readonly token: BehaviorSubject<AuthorizationTokenModel>;
  private languageService: LanguageService;
  private readonly accessInfo: BehaviorSubject<IAccessInfo[]>;
  private accessInfoLocal: IAccessInfo[] = [];
  SUPER_ADMIN = 'Super Admin';
  ADMIN = 'admin';

  get accessToken(): string {
    const token = this._getToken()?.accessToken;
    return token ? this.encryptionHelper.decrypt(token) : token;
  }

  get expiresAt(): number {
    return this._getToken()?.expiresAt;
  }

  get expiresIn(): number {
    return this._getToken()?.expiresIn;
  }

  get isAuthenticated(): boolean {
    return localStorage.getItem(AUTHORIZATION_CLIENT.SESSION_TOKEN) !== null;
  }

  get loggedInUser(): IUserInfo {
    return this._getUser();
  }

  get isNovatiqUser(): boolean {
    return this.userOrganisationType === ORGANISATION_TYPE.NOVATIQ;
  }

  get isAdmin(): boolean {
    return this._getUser()?.roleName?.toLowerCase().includes(this.ADMIN);
  }

  get isNovatiqAdmin(): boolean {
    return this.isAdmin && this.userOrganisationType === ORGANISATION_TYPE.NOVATIQ;
  }

  get isSuperAdmin(): boolean {
    return this._getUser()?.roleName === this.SUPER_ADMIN;
  }

  get isNovatiqSuperAdmin(): boolean {
    return this._getUser()?.roleName === this.SUPER_ADMIN && this.userOrganisationType === ORGANISATION_TYPE.NOVATIQ;
  }

  get isTelco(): boolean {
    return this.userOrganisationType === ORGANISATION_TYPE.TELCO;
  }

  get isBuySide(): boolean {
    return this.userOrganisationType === ORGANISATION_TYPE.BUY_SIDE;
  }

  get isPublisher(): boolean {
    return (
      this.userOrganisationType === ORGANISATION_TYPE.BUYSIDE_PUBLISHER ||
      this.userOrganisationType === ORGANISATION_TYPE.DISTRIBUTION_PUBLISHER
    );
  }

  get isAgency(): boolean {
    return this.userOrganisationType === ORGANISATION_TYPE.AGENCY;
  }

  get userOrganisationType() {
    return this._getUser()?.organisation?.type;
  }

  get refreshTokenIn(): number {
    const value = 45000;
    return this._getToken()?.refreshTokenIn || value;
  }

  get sessionExpired(): boolean {
    return Date.now() > this.expiresAt && this.isAuthenticated;
  }

  get landingPage(): string {
    const landingPage = this._getUser()?.landingPage;
    if (landingPage) {
      const data = navigationMenuItems.filter((navigationMenuItem) => navigationMenuItem.name === landingPage)[0];
      return data.routerLink[0];
    } else {
      // if user has access to audience builder then redirect user there
      // else to 1st page from the list of functions
      const hasAudienceAcces = this.hasPermission({
        function: ROUTES.AUDIENCE_BUILDER.name,
        subFunction: SUB_FUNCTION.ATTRIBUTES,
        operation: OPERATION.READ
      });
      if (hasAudienceAcces) {
        return ROUTES.AUDIENCE_BUILDER.url;
      } else {
        const functionName = this.findFirstFunctionWithAccess(this._getAccess());
        const data = navigationMenuItems.filter((navigationMenuItem) => navigationMenuItem.name === functionName)[0];
        return data.routerLink[0];
      }
    }
  }

  get isInternalTrafficker(): boolean {
    return this._getUser()?.organisation?.isInternal;
  }

  get userAccessInfo(): IAccessInfo[] {
    return this._getAccess();
  }

  get locale(): string {
    return this._getUser()?.language;
  }

  constructor(
    private readonly encryptionHelper: EncryptionHelper,
    private readonly _geographyHelper: NovatiqGeographyHelper,
    private injector: Injector
  ) {
    this.currentUser = new BehaviorSubject<IUserInfo>(this._getUser());
    this.token = new BehaviorSubject<AuthorizationTokenModel>(this._getToken());
    this.accessInfo = new BehaviorSubject<IAccessInfo[]>(this._getAccess());
  }

  addToken(authResponse: AuthorizationTokenModel): void {
    this._setSession(authResponse);
  }

  addUser(authResponse: IUserInfo): void {
    this._setUserSession(authResponse);
  }

  addAccess(authResponse: IAccessInfo[]): void {
    this._setUserAccess(authResponse);
  }

  getCurrentUser() {
    return this.currentUser.asObservable();
  }

  getToken() {
    return this.token.asObservable();
  }

  getAccessInfo() {
    return this.accessInfo.asObservable();
  }

  removeUserSession() {
    this._removeSession();
  }

  refreshUserSession(tokenResponse) {
    this._refreshSession(tokenResponse);
  }

  private _getToken(): AuthorizationTokenModel {
    return JSON.parse(localStorage.getItem(AUTHORIZATION_CLIENT.SESSION_TOKEN)) as AuthorizationTokenModel;
  }

  private _getUser(): IUserInfo {
    return JSON.parse(localStorage.getItem(AUTHORIZATION_CLIENT.SESSION_USER)) as IUserInfo;
  }

  private _getAccess(): IAccessInfo[] {
    return JSON.parse(localStorage.getItem(AUTHORIZATION_CLIENT.SESSION_ACCESS)) as IAccessInfo[];
  }

  private _refreshSession(tokenResponse: any) {
    const token = this._transformAccessToken(tokenResponse.accessToken, tokenResponse.expiresIn);
    this._setToken(token);
  }

  public updateDefaultLanguage(defaultLang: string) {
    const authenticatedUser = this._getUser();
    if (authenticatedUser) {
      authenticatedUser.language = defaultLang;
      this._setUserSession(authenticatedUser);
    }
  }

  private _removeSession() {
    this.currentUser.next(null);
    localStorage.removeItem(AUTHORIZATION_CLIENT.SESSION_TOKEN);
    this._geographyHelper.clearGeographies();
    localStorage.removeItem(AUTHORIZATION_CLIENT.SESSION_USER);
    //clear locale
    sessionStorage.removeItem(AUTHORIZATION_CLIENT.LOCALE);
    if (!this.languageService) {
      this.languageService = this.injector.get(LanguageService);
    }
    this.languageService.clearLocale();
  }

  private _transformAccessToken(accessToken: string, expiresIn: number, tokenType = 'bearer'): AuthorizationTokenModel {
    const value = 100;
    const expiresInSeconds = expiresIn * TIME_CONVERSION.TIME_MINUTE_TO_SECONDS;
    return {
      accessToken: this.encryptionHelper.encrypt(accessToken),
      expiresAt: expiresInSeconds * TIME_CONVERSION.TIME_SECONDS_TO_MILLISECONDS + Date.now(),
      expiresIn: expiresInSeconds,
      refreshTokenIn: (expiresInSeconds - value) * TIME_CONVERSION.TIME_SECONDS_TO_MILLISECONDS,
      tokenType
    } as AuthorizationTokenModel;
  }

  private _setSession(authResult) {
    const token = this._transformAccessToken(authResult.accessToken, authResult.expiresIn, authResult.tokenType);
    this._setToken(token);
  }

  private _setToken(token: AuthorizationTokenModel) {
    this.token.next(token as AuthorizationTokenModel);
    localStorage.setItem(AUTHORIZATION_CLIENT.SESSION_TOKEN, JSON.stringify(token));
  }

  private _setUserSession(userInfo: IUserInfo) {
    this.currentUser.next(userInfo as IUserInfo);
    localStorage.setItem(AUTHORIZATION_CLIENT.SESSION_USER, JSON.stringify(userInfo));
  }

  private _setUserAccess(accessInfo: IAccessInfo[]) {
    this.accessInfo.next(accessInfo as IAccessInfo[]);
    localStorage.setItem(AUTHORIZATION_CLIENT.SESSION_ACCESS, JSON.stringify(accessInfo));
  }

  /**
   * Checks if the user has the required permissions.
   * @param componentPermission The required permission to check.
   * @param userPermissions (Optional) User permissions. If not provided, the method will use the stored access info.
   * @returns {boolean} True if permission is granted; otherwise, false.
   */
  hasPermission(componentPermission: IComponentPermission): boolean {
    const userPermissions = this._getAccess();
    if (!userPermissions.length || !componentPermission) {
      return false; // No permissions or no component permission set
    }

    const functionName = componentPermission.function.toUpperCase();
    const subFunctionName = componentPermission.subFunction?.toUpperCase();
    const operationName = componentPermission.operation.toUpperCase();

    // Find the corresponding function permission
    const functionPermission = userPermissions.find((fn) => fn.functionName.toUpperCase() === functionName);

    // If the function permission exists, check sub-function and operation permissions
    return (
      functionPermission?.subFunctions?.some(
        (subFn) =>
          subFn.subFunctionName.toUpperCase() === subFunctionName &&
          subFn.operations?.some((op) => op.name.toUpperCase() === operationName && op.hasAccess)
      ) || false
    );
  }

  // Function to find the first functionName with hasAccess true
  findFirstFunctionWithAccess(data: IAccessInfo[]): string | null {
    for (const func of data) {
      for (const subFunc of func.subFunctions) {
        for (const operation of subFunc.operations) {
          if (operation.name === 'read' && operation.hasAccess) {
            return func.functionName; // Return the functionName as soon as we find hasAccess true
          }
        }
      }
    }
    return null; // Return null if no function with hasAccess true is found
  }
}
