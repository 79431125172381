import { CommonAPIService } from '@/_services';
import { IMetadata } from '@/_types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonAPIHelper {
  constructor(private readonly commonAPIService: CommonAPIService) {}

  getGeographies() {
    return this.commonAPIService.getGeographies();
  }

  getOrganisationTypes(): Observable<IMetadata> {
    return this.commonAPIService.getMetadata('Organisation', 'Organisation Type');
  }

  getRoleTypes(): Observable<IMetadata> {
    return this.commonAPIService.getMetadata('Role', 'Role Type');
  }
}
